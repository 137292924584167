// global BarcodeDetector

import { LitElement, PropertyValueMap, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { requestPersistedStorage, gravatarUrl, hasPermission } from '../lib/utils.js';

import { Ticket } from '../models/ticket.js';

import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/qr-code/qr-code.js';

import { Html5QrcodeScanner, Html5QrcodeScannerState, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';

import { styles } from '../styles/shared-styles';
import { when } from '@thepassle/app-tools/utils.js';

import '../components/footer.js';
import '../components/menu.js';

import {configStore, ticketsStore, settingsStore} from '../stores.js';
import { withStores } from "@nanostores/lit";

let scanner;
@customElement('app-home')
export class AppHome extends withStores(LitElement, [configStore, ticketsStore, settingsStore]) {
    // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'Welcome!';
  @property() decodedText = '';
  @property({ type: Array }) supportedFormats = [];
  @property({ type: Boolean }) scanned = false;

  @state() validating = false;
  @state() ticket = null;
  @state() configScanned = false;
  @state() paused = false;

  @state() scannerState = null;
  @state() scannerHasCameraPermission = null;

  validatingTimeout: number | undefined;

  get config() {
    return configStore.get();
  }

  set config(value) {
    configStore.set(value);
  }

  get settings() {
    return settingsStore.get();
  }

  set settings(value) {
    settingsStore.set(value);
  }

  get tickets() {
    return ticketsStore.get();
  }

  set tickets(value) {
    ticketsStore.set(value);
  }

  protected willUpdate(prevProps: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.willUpdate(prevProps);

    if (prevProps.has('paused') && this.paused !== undefined) {
      console.log('pause', this.paused);
      try {
        this.paused ? scanner.pause() : scanner.resume();
      } catch (error) {

      }
    }


    const { NOT_STARTED, SCANNING } = Html5QrcodeScannerState


    if (prevProps.has('scannerState') && this.dashboard) {
      this.resolveDashboardVisibility()
    }
  }

  async resolveDashboardVisibility() {
    const hasCameraPermission = await hasPermission('camera');
    console.log('this.scannerState', this.scannerState)
    console.log('this.scannerhasCameraPermission', hasCameraPermission)
    const { NOT_STARTED, SCANNING } = Html5QrcodeScannerState
    this.dashboard.hidden = hasCameraPermission;

    this.dashboard.classList.toggle('has-camera-permission', hasCameraPermission);

    setTimeout(() => {
      // when changing routes
      if(!this.dashboard) return;

      this.dashboard.hidden = hasCameraPermission && this.scannerState == SCANNING;
    }, 1300);
  }

  static styles = [
    styles,
    css`
      .validating {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--sl-color-neutral-100);
      }

      .validating .content {
        text-align: center;
        width:50vw;
      }

      .validating.valid {
        transition: background-color var(--sl-transition-medium) ease;

        background: var(--sl-color-emerald-300);
      }

      .validating.invalid {
        background: var(--sl-color-red-300);
      }

      .validating.but_used {
        background: var(--sl-color-orange-300);
      }

      .tap-note {
        position: absolute;
        bottom: 50px;
        width: 100%;
        text-align: center;
        left: 0;
        text-transform: uppercase;
        font-weight: bold;
        color: var(--sl-color-neutral-1000);

      }

      app-header sl-button::part(base) {
        color: var(--sl-color-neutral-600);
      }

      app-header sl-button::part(base):active {
        color: var(--sl-color-neutral-800);
      }

      sl-spinner {
        --indicator-color: var(--sl-color-neutral-1000);
      }

      .boxes {
        display: flex;
      }

      .boxes div {
        width: 50%;
        padding: var(--sl-spacing-small);
        border: 1px solid red;
        text-align: center;
        font-weight: bold;
      }

      .boxes .black {
        background: var(--absolute-black);
        color: var(--absolute-white);
      }

      .boxes .white {
        color: var(--absolute-black);
        background: var(--absolute-white);
      }
    `,
  ];

  barcodeDetector: any;

  get hasCameraPermission() {
    return scanner.persistedDataManager.hasCameraPermissions()
  }

  connectedCallback(): void {
    super.connectedCallback();
    window.app = this;
    document.addEventListener('visibilitychange', this);
    this.addEventListener('sl-after-hide', this);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.observer.disconnect();
    this.stop();
    scanner.clear();
    document.addEventListener('visibilitychange', this);
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    this.setupScanner();
  }

  get reader() {
    return document.querySelector('#reader') as HTMLElement;
  }

  get main() {
    return document.querySelector('main') as HTMLElement;
  }

  get lastTicket() {
    return this.tickets.values().next().value;
  }

  async initialSetup(decodedText: string) {
    console.log('initialSetup', decodedText)
    try {
      const config = JSON.parse(decodedText);
      await requestPersistedStorage();
      this.config = config;
      this.configScanned = true;
    } catch (error) {
      console.log('error', error);
    }

    return this.configScanned;
  }

  get setupIncomplete() {
    return !this.config?.name;
  }

  async onScanSuccess(decodedText: string, decodedResult: object) {
    console.log(`Code matched = ${decodedText}`, decodedResult);


    this.decodedText = decodedText;

    const isInitialSetup = decodedText.includes('token');
    console.log('isInitialSetup', isInitialSetup)
    if(isInitialSetup) {
      this.paused = true;
      await this.initialSetup(decodedText);
      return;
    }

    if (this.setupIncomplete) return;
    this.paused = true;

    this.scanned = true;

    this.validating = true;
    this.ticket = null;

    await this.validateURL();

    this.validatingTimeout = setTimeout(() => {
      this.validating = false;
      this.paused = false;
    }, this.ticket?.fullyValid ? 1000 : 60*1000);
  }

  tapToDismiss() {
    clearTimeout(this.validatingTimeout);
    this.validating = false;
    this.paused = false;
  }

  start() {
    const startButton = document.querySelector('#html5-qrcode-button-camera-start') as HTMLButtonElement;
    startButton?.click();
    this.paused = false;
  }

  stop() {
    const stopButton = document.querySelector('#html5-qrcode-button-camera-stop') as HTMLButtonElement;
    this.paused = true;
  }

  handleEvent(event: Event) {
    console.log('event', event);
    event.type === 'visibilitychange' && this.onVisibilityChange();
    event.type === 'sl-after-hide' && this.requestUpdate();
  }

  onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.start();
    } else {
      this.stop();
    }
  }

  onScanFailure(_error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    //console.log(`Code scan error = ${error}`);
  };

  // Scan barcode with camera
  async setupScanner() {
    scanner = new Html5QrcodeScanner(
      'reader',
      {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ]
      },
      /* verbose= */ false
    );

    window.scanner = scanner;

    // const hasCameraPermission = await hasPermission('camera');

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // console.log(mutation);
        this.scannerState = scanner?.getState?.();
        this.scannerHasCameraPermission = scanner?.persistedDataManager.hasCameraPermissions();
      });
    });

    this.observer.observe(document.querySelector('#reader'), {
      attributes: true,
      childList: true,
      subtree: true,
      // characterData: true,
    });

    scanner.render(this.onScanSuccess.bind(this), this.onScanFailure.bind(this));

    // alert(JSON.stringify({state: scanner.getState(), ...scanner.persistedDataManager.data}))

    const hasCameraPermission = scanner.persistedDataManager.hasCameraPermissions();

    // using mutation observer track changes to text in this.dashboard
  }

  async validateURL() {
    const url = new URL(this.decodedText);
    const code = url.searchParams.get('code');

    this.ticket = Ticket.find(code);

    this.ticket ??= new Ticket({
      url: this.decodedText,
      code,
      scannedAt: new Date(),
    });

    if (!this.ticket.valid) {
      this.ticket = await this.ticket.validate();
    };


    if (!this.ticket.valid) return;
    if (this.ticket.usedAt) return;
    if (this.settings.offlineMode) return;

    this.ticket = await this.ticket.validateRemote();
  }

  get ticketState() {
    if (this.ticket.notTicket) return 'not_ticket';

    const valid = this.ticket?.valid;
    switch (valid) {
      case null:
      case undefined:
        return undefined;
      default:
        return valid ? 'valid' : 'invalid';
    }
  }

  renderUsedNote() {
    return html`<div>Ticket is valid but used<br> ${this.ticket?.usedAt})</div>`;
  }

  renderValidating() {
    if(!this.validating) return;
    if (!this.ticket) return;

    const text =
      {
        'valid': 'Valid ticket',
        'invalid': 'Invalid ticket',
        'not_ticket': 'Not a ticket',
      }[this.ticketState];


    const usedClass = this.ticket.usedAt ? 'but_used' : '';

    return html` <div class="validating ${this.ticketState} ${usedClass}" @click=${this.tapToDismiss}>
      <div class="content">
        <h1>${text}</h1>
        <h2>${this.ticket.code}</h2>

        ${when(this.ticket.remoteCheckPending,
          () => html`<div><sl-spinner></sl-spinner> Validating ticket with server...</div>`,
          () => html`${when(this.ticket.usedAt, () => this.renderUsedNote())}`
        )}

      </div>

      <div class="tap-note">Tap to dismiss</div>

    </div> `;
  }

  get dashboard(): HTMLElement {
    return document.querySelector('#reader__dashboard_section_csr');
  }

  toggleScanner() {
    this.dashboard.hidden = !this.dashboard.hidden
  }

  get gravatarUrl() {
    return this.config?.email ?? gravatarUrl(this.config?.email);
  }

  onSuccessfulDialogShow() {
    this.paused = true;
  }

  onSuccessfulDialogHide() {
    this.paused = false;
    this.configScanned = false;
  }

  renderSuccessfulDialog() {
   return html`
      <sl-dialog
        @sl-after-show=${this.onSuccessfulDialogShow}
        @sl-after-hide=${this.onSuccessfulDialogHide}
        label="Setup complete" class="dialog-overview" open>
           Welcome <strong>${this.config.name}</strong> you have successfully setup your ticket scanner.
    </sl-dialog>
  `;
  }

  get isSamsungBrowser() {
    return navigator.userAgent.includes('SamsungBrowser');
  }


  get samsungDialog() {
    return this.shadowRoot.querySelector('#samsung-dialog');
  }

  closeSamsungDialog() {
    this.samsungDialog?.hide()
  }

  dismissSamsungDialog(e) {

    this.closeSamsungDialog();

    this.settings = {
      ...this.settings,
      samsungBrowserDialogDismissed: true,
    };

    console.log('dismissSamsungDialog', this.settings)
  }

  get isAnyDialogOpened() {
    return this.shadowRoot.querySelector('sl-dialog[open]');
  }

  get showSamsungDialog() {
    return !this.isAnyDialogOpened && this.isSamsungBrowser && !this.settings?.samsungBrowserDialogDismissed;
  }

  updateSettings(e) {
    this.settings = {
      ...this.settings,
      samsungBrowserDialogDismissed: e.target.checked,
    };
  }



  renderSamsungDialog() {
    if (!this.showSamsungDialog) return;

    return html`
      <sl-dialog id="samsung-dialog" label="Samsung browser" class="dialog-overview" open>

        <p>For best experience please use Chrome.</p>

        <p>If you see <strong>Gray box</strong> and wrong <strong>text colors</strong>,
        you need to disable Samsung internet browser experimental settings:</p>
        <ol>
          <li>Open Samsung Internet</li>
          <li>Navigate to Settings > Labs > Use website dark theme</li>
        </ol>

        <div class="boxes">
          <div class="black">Black box<br>White text</div>
          <div class="white">White box<br>Black text</div>
        </div>

        <p><sl-switch @sl-input=${this.updateSettings}>Don't show this again</sl-switch></p>
      </sl-dialog>
    `;
  }

  renderInitialDialog() {
    if (!this.showInitialSetupDialog) return;

    return html`
      <sl-dialog label="Welcome to the Ticket check" class="dialog-overview" ?open=${this.setupIncomplete}>
        <p>Please read the following instructions:</p>

        <ol>
          <li>Close this window</li>
          <li>Request camera permission</li>
          <li>Scan your personal QR code</li>
        </ol>

        <p>Created with much ❤️ by Effectiva studio</p>
      </sl-dialog>
    `;
    }


  get showSuccessfulSetupDialog() {
    return this.configScanned && this.config?.name;
  }

  get showInitialSetupDialog() {
    return this.setupIncomplete
  }


  render() {
    return html`

      ${this.renderSamsungDialog()}
      ${this.renderInitialDialog()}
      ${this.showSuccessfulSetupDialog ? this.renderSuccessfulDialog() : null}

      <app-header logo>
        <sl-button slot="right" @click=${this.toggleScanner} name="camera" title="Camera settings" variant="text" circle>
          <sl-icon name="camera" label="Camera" style="font-size: var(--sl-font-size-2x-large)"></sl-icon>
        </sl-button>
      </app-header>

      <main>
        <slot></slot>
        ${this.renderValidating()}
      </main>

      <app-footer></app-footer>

    `;
  }
}

