import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';

@customElement('app-footer')
export class AppFooter extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      background-color: var(--app-background-color);
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-inline: var(--sl-spacing-large);
      padding-block: var(--sl-spacing-x-small);
      padding-bottom: max(env(safe-area-inset-bottom), var(--sl-spacing-x-small));

    }

    sl-button {
      flex: 1;
    }

    sl-button[variant="neutral"]::part(base) {
      border: none;
      color: var(--sl-color-neutral-950);
      background-color: var(--sl-color-neutral-200);
      font-weight: bold;
    }

    sl-button[variant="neutral"][outline]::part(base) {
      background-color: transparent;
    }

    sl-button[variant="neutral"][outline]:active::part(base) {
      background-color: var(--sl-color-neutral-400);
    }

    sl-button + sl-button {
      margin-inline-start: var(--sl-spacing-small);
    }

  `;

  renderButton({ name, href }: { name: string; href: string }) {
    const active = window.location.pathname == href;
    const variant = active ? 'neutral' : 'text';
    const outline = !active;
    return html`<sl-button pill size="large" name="${name}" href="${href}" variant="neutral" ?outline=${outline}>
      ${name}
    </sl-button>`;
  }

  render() {
    return html`
      ${this.renderButton({ name: 'Check', href: resolveRouterPath() })}
      ${this.renderButton({ name: 'History', href: resolveRouterPath('about') })}
    `;
  }
}
