import { LitElement, PropertyValueMap, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { gravatarUrl } from '../lib/utils.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { when } from 'lit/directives/when.js';

@customElement('app-avatar')
export class AppMenu extends LitElement {
  static styles = css`
    :host {
      position: relative;
    }

    .rich-avatar {
      position: relative;
    }

    sl-badge::part(base) {
      --size: 12px;

      padding: 0;
      width: var(--size);
      height: var(--size);
    }

    .rich-avatar sl-badge {
      position: absolute;
      top: 5px;
      right: -2px;
    }

    sl-avatar {
      --size:var(--sl-font-size-2x-large);
    }
  `;

  @property() name = '';
  @property() email = '';
  @property({ type: Boolean }) indicator = false;
  @state() gravatarUrl = undefined;

  get initials() {
    const name = this.name;
    if (!name) return;

    return name.split(' ').map((n: string) => n[0]).slice(0,2).join('');
  }

  protected async willUpdate(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.willUpdate(changedProperties);

    if (this.email && changedProperties.has('email')) {
      this.gravatarUrl = await gravatarUrl(this.email);
    }
  }


  render() {
    return html`
    <div class="rich-avatar">
      <sl-avatar initials=${ifDefined(this.initials)} image=${this.gravatarUrl} label=${this.name || 'Anonymous'}>
      </sl-avatar>
      ${when(this.indicator, () => html`<sl-badge variant="primary" pill></sl-badge>`)}
    </div>
    `;
  }
}
