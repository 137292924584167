import {  validateCode, parseDate, asyncDelay } from '../lib/utils.js';
import { configStore, ticketsStore, settingsStore, generalStore } from '../stores.js';

export class Ticket {
  constructor(attributes) {

    Object.assign(this, attributes);

    this.scannedAt = parseDate(this.scannedAt)
    this.syncedAt = parseDate(this.syncedAt)
    this.usedAt = parseDate(this.usedAt)
  }

  static get tickets() {
    return ticketsStore.get();
  }

  static find(code) {
    const tickets = ticketsStore.get();
    return tickets?.get(code);
  }

  static get counters() {
    const counters = {
      total: 0,
      synced: 0
    };

    for (const ticket of this.tickets.values()) {
      counters.total++;
      if(ticket.synced) counters.synced++;
    }

    return counters;
  }

  static get syncPercentage() {
    const { counters } = this;
    return Math.round(counters.synced / counters.total * 100);
  }

  save() {
    const tickets = new Map(ticketsStore.get());
    const ticket = new Ticket(this);
    tickets.set(this.code, this);
    ticketsStore.set(tickets);

    return ticket;
  }

  get scannedAtHuman() {
    return new Intl.DateTimeFormat('uk', {
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(this.scannedAt)
  }

  get serverConfirmed() {
    return this.usedAt;
  }

  get syncedStatus() {

    if(this.remoteCheckPending) return 'syncing'

    if(this.valid) {
      return this.synced ? 'synced' : 'pending';
    }else {
      return 'invalid';
    }
  }

  get syncedIcon() {
    return {
      synced: 'check-circle',
      pending: 'clock',
      syncing: 'arrow-repeat',
    }[this.syncedStatus];
  }

  get statusIcon() {
    if (this.valid && this.synced) return 'check-circle';
    if (this.valid) return 'check';
    return 'x-circle';
  }

  toJSON() {
    return {
      code: this.code,
      scannedAt: this.scannedAt,
      url: this.url,
      valid: this.valid,

      syncedAt: this.syncedAt,

      // remote attributes
      usedAt: this.usedAt,
      status: this.status
    }
  }

  get fullyValid() {
    return this.valid && !this.usedAt;
  }

  async validate() {
    const secret = configStore.get().secret;

    if (!this.code){
      this.notTicket = true
      return this;
    }
    this.valid = await validateCode(this.code, secret);
    return this.save();
  }

  static get lastSyncedAt() {
    return generalStore.getKey('lastSyncedAt');
  }

  static set lastSyncedAt(date) {
    generalStore.setKey('lastSyncedAt', date);
  }

  static async syncAll() {
    if (this.offlineMode) return;
    generalStore.setKey('syncing', true);

    this.lastSyncedAt = new Date();
    await Promise.all([
      this.validateAll(),
      this.pullAll(),
    ]);

    generalStore.setKey('syncing', false);
  }

  static get syncing() {
    return generalStore.get().syncing;
  }

  static async periodicSyncAll() {
    console.log('periodic sync');
    this.stopPeriodicSync();
    await this.syncAll();
    this.periodicSyncAllTimeout = setTimeout(() => this.periodicSyncAll(), 10 * 1000);
  }

  static stopPeriodicSync() {
    clearTimeout(this.periodicSyncAllTimeout);
  }

  static async pullAll() {
    await asyncDelay();
    console.log('pulling all tickets');
  }

  static async validateAll() {
    let count = 0;
    for (const [code, ticket] of this.tickets) {
      if(ticket.synced) continue;
      count++;
      // const ticket = Ticket.find(code)
      ticket.validateRemote();
    }
    return count;
  }

  get synced() {
    return Boolean(this.syncedAt);
  }

  get used() {
    return Boolean(this.usedAt);
  }

  get scanned() {
    return Boolean(this.scannedAt);
  }

  get offlineMode() {
    return settingsStore.get().offlineMode;
  }

  async validateRemote() {
    const API_TOKEN = configStore.get().token;

    if (this.offlineMode) return this;


    this.remoteCheckPending = true;

    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${API_TOKEN}`,
        }
      });

      const { usedAt, status } = await response.json();
      this.usedAt = parseDate(usedAt);
      this.status = status;

      this.justValidated = true;
      this.syncedAt = new Date();

    } catch (error) {
      console.error(error.message);
    } finally {
      await asyncDelay(1000);

      this.remoteCheckPending = false;
      return this.save();
    }

  }

}


window.Ticket = Ticket;
