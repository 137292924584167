import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import { when } from 'lit/directives/when.js';
import { pwa } from '@thepassle/app-tools/pwa.js';

// window.pwa = pwa;

import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import './avatar.js';


import { configStore } from '../stores.js';
import { withStores } from "@nanostores/lit";

@customElement('app-menu')
export class AppMenu extends withStores(LitElement, [configStore]) {
  static styles = css`
    :host {
      position: relative;
    }

    .rich-avatar {
      position: relative;
    }

    sl-badge::part(base) {
      --size: 12px;

      padding: 0;
      width: var(--size);
      height: var(--size);
    }

    .rich-avatar sl-badge {
      position: absolute;
      top: 5px;
      right: -2px;
    }

    sl-avatar {
      --size:var(--sl-font-size-2x-large);
    }

    sl-button::part(base):active {
      background-color: var(--sl-color-neutral-700);
    }

    sl-menu-item::part(base) {
      min-width: 200px;
      padding-block: var(--sl-spacing-small);
    }

    a {
      text-decoration: none;
    }
  `;

  get config() {
    return configStore.get();
  }

  set config(value) {
    configStore.set(value);
  }

  get initials() {
    const name = this.config?.name;
    if (!name) return;

    return name.split(' ').map((n: string) => n[0]).slice(0,2).join('');
  }

  connectedCallback() {
    super.connectedCallback();

    pwa.addEventListener('update-available', this);
    pwa.addEventListener('installable', this);
    pwa.addEventListener('installed', this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    pwa.removeEventListener('update-available', this);
    pwa.removeEventListener('installable', this);
    pwa.removeEventListener('installed', this);
  }

  handleEvent(e: Event) {
    if ('installed' in e) pwa.isInstalled = e.installed;
    this.requestUpdate();
  }

  logout() {
    if (!confirm('Are you sure you want to logout?')) return;

    this.config = {};
  }

  get showInstall() {
    return pwa.installable && !pwa.isInstalled;
  }

  get showIndicator() {
    return pwa.updateAvailable || this.showInstall;
  }

  get showUpdate() {
    return pwa.updateAvailable;
  }

  shareApp() {
    if (!navigator.share) return;

    navigator.share({
      title: 'Ticket check app',
      text: 'Ticket validation app for QR codes!',
      url: window.location.origin,
    });
  }

  renderAvatar() {
    return html`
      <app-avatar .name=${this.config?.name} .email=${this.config?.email} .indicator=${this.showIndicator}></app-avatar>
    `;
  }

  render() {
    return html`
      <sl-dropdown>
      <sl-button name="main_menu" title="Main menu" slot="trigger" variant="text" circle>${this.renderAvatar()}</sl-button>
      <sl-menu>
        ${when(this.showUpdate, () => html`<sl-menu-item @click=${pwa.update}>Update app<sl-badge variant="primary" pill></sl-badge></sl-menu-item>`)}
        ${when(this.showInstall, () => html`<sl-menu-item @click=${pwa.triggerPrompt}>Install app<sl-badge variant="primary" pill></sl-badge></sl-menu-item>`)}
        <a href=${resolveRouterPath('settings')}><sl-menu-item>Settings</sl-menu-item></a>
        ${when(navigator.share, () => html`<sl-menu-item @click=${this.shareApp}>Share app</sl-menu-item>`)}
        <a href=${resolveRouterPath('info')}><sl-menu-item>About</sl-menu-item></a>
        <sl-divider></sl-divider>
        <sl-menu-item @click=${this.logout}>Logout</sl-menu-item>
      </sl-menu>
      </sl-dropdown>
    `;
  }
}
