import { css } from 'lit';


// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`

  *, *:before, *:after {
    box-sizing: border-box;
  }

  button {
    font-size: var(--sl-font-size-medium);
  }

  main {
    padding-top:1px;
    margin-top: var(--sl-spacing-4x-large);
    padding-inline: var(--sl-spacing-medium);
    padding-bottom: 80px;
  }

  small {
    color: var(--sl-color-neutral-600);
  }

  main.home {
    margin-top: 40px;
  }

  dl {
    border-bottom: 1px solid var(--sl-color-neutral-300);
    padding-bottom: var(--sl-spacing-large);
    margin-bottom: var(--sl-spacing-large);

  }

  dt {
    font-size: var(--sl-font-size-large);
    margin-bottom: var(--sl-spacing-2x-small);
  }

  dt:first-letter {
    text-transform: uppercase;
  }

  dd {
    font-size: var(--sl-font-size-large);
    color: var(--sl-color-neutral-700);
    padding:0;
    margin:0;
    word-break: break-word;
  }

  dl:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }

  dl:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ul.list2,
  ul.list sl-switch::part(base),
  section.hero,
  section.card {
    border-radius: var(--sl-border-radius-large);
    background-color: var(--sl-color-neutral-100);
    padding: var(--sl-spacing-large);
  }

  section {
    margin-bottom: var(--sl-spacing-2x-large);
  }

  ul.list sl-switch::part(base) {
    margin-bottom: 0;
  }

  ul.list sl-switch::part(base){
    display: flex;
    align-items: start;
  }

  ul.list sl-switch::part(base):active{
    background-color: var(--sl-color-neutral-200);
  }

  ul.list sl-switch {
    display: block;
    width: 100%;
  }

  ul.list .desc {
    display: block;
    margin-top: var(--sl-spacing-x-small);
    padding-top: var(--sl-spacing-x-small);
    border-top: 1px solid var(--sl-color-neutral-300);
    font-size: var(--sl-font-size-small);
    color: var(--sl-color-neutral-700);

  }

  .section-title {
    color: var(--sl-color-neutral-700);
    font-size: var(--sl-font-size-large);
    font-weight: bold;
    margin-bottom: var(--sl-spacing-x-small);
    padding-left: var(--sl-spacing-large);
  }


  section.hero {
    position:relative;
    text-align: center;
    margin-top: var(--sl-spacing-2x-large);
    padding-top: var(--sl-spacing-2x-large);
  }

  section.hero sl-avatar {
   position: absolute;
   top: 0;
   transform: translate(-50%, -50%);
  }

  ul.list2,
  ul.list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: var(--sl-font-size-large);
  }

  ul.list2 li {
    margin:0;
    padding:0;
  }

  ul.list2 > button,
  ul.list2 > .button,
  ul.list li {
    margin:0;
    border-bottom: 1px solid var(--sl-color-neutral-300);
    padding: var(--sl-spacing-large) 0;
    display: flex;
    align-items: center;
    width: 100%;
  }

  ul.list2 li > .button,
  ul.list2 li > button {
    background-color: transparent;
    border:none;
    display:flex;
    width: 100%;
    padding: var(--sl-spacing-large);
    border-bottom: 1px solid var(--sl-color-neutral-200);
    align-items: center;
    font-size: var(--sl-font-size-medium);
    text-decoration: none;
    color: var(--sl-color-neutral-700);
  }

  ul.list2 li:first-child > .button,
  ul.list2 li:first-child > button {
    border-radius: var(--sl-border-radius-large) var(--sl-border-radius-large) 0 0;
  }

  ul.list2 li:last-child > .button,
  ul.list2 li:last-child > button {
    border-bottom: none;
    border-radius: 0 0 var(--sl-border-radius-large) var(--sl-border-radius-large);
  }

  ul.list2 li > .button:hover,
  ul.list2 li > button:hover {
    background-color: var(--sl-color-neutral-200);
  }

  ul.list2 li > .button:active,
  ul.list2 li > button:active {
    background-color: var(--sl-color-neutral-300);
  }

  ul.list2 li > .button.selected,
  ul.list2 li > button.selected {
    background-color: var(--sl-color-neutral-0);
  }

  ul.list.settings li {
    border:none;
    padding-bottom: 0;
  }

  ul.list li :first-child {
    margin-right: var(--sl-spacing-small);
  }

  ul.list :last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }

  ul.list small {
    font-size: var(--sl-font-size-small);
    color: var(--sl-color-neutral-500);
    margin-left: auto;
  }

  .empty {
    text-align: center;
    margin-block: var(--sl-spacing-4x-large);
  }

  .empty sl-icon {
    font-size: 5em;
  }

`;
