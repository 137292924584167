
export function asyncDelay(n = 500, withError = false) {
  return new Promise((resolve, reject) => {
    setTimeout(withError ? reject : resolve, n);
  });
}

// Request persistent storage for site
export async function requestPersistedStorage() {
  console.log('Requesting persistent storage');
  if (navigator.storage && navigator.storage.persist) {
    console.log('awaiting Requesting persistent storage');
    const isPersisted = await Promise.race([navigator.storage.persist(), asyncDelay(200)]);
    console.log(`Persisted storage granted: ${isPersisted}`);
    return isPersisted;
  }

  return false;
}

async function hexDigestSha256(string) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}

window.hexDigestSha256 = hexDigestSha256;

export async function getChecksum(data) {
  return (await hexDigestSha256(data)).slice(0, 2).toUpperCase();
}

export async function validateCode(code, secret) {

  // For dev testing
  if(code.startsWith('XX-XX')) return true;

  const [event_id, number, checksum] = code.split('-');

  const data = [event_id, number, secret].join('');
  const expectedChecksum = await getChecksum(data);

  return checksum === expectedChecksum;
}

/**
 *
 * Query `?d=` parameters options:
 *
 * 404: do not load any image if none is associated with the email hash, instead return an HTTP 404 (File Not Found) response
 * mp: (mystery-person) a simple, cartoon-style silhouetted outline of a person (does not vary by email hash)
 * identicon: a geometric pattern based on an email hash
 * monsterid: a generated ‘monster’ with different colors, faces, etc
 * wavatar: generated faces with differing features and backgrounds
 * retro: awesome generated, 8-bit arcade-style pixelated faces
 * robohash: a generated robot with different colors, faces, etc
 * blank: a transparent PNG image (border added to HTML below for demonstration purposes)
 *
 * @param {string} email
 * @returns {string}
 */
export async function gravatarUrl(email) {
  if(!email) return;

  const type = '404'; // return null if no gravatar
  const hash = await hexDigestSha256(email.trim().toLowerCase());
  const url = `https://www.gravatar.com/avatar/${hash}?d=${type}`;

  return url;
}

window.validateCode = validateCode;


export function parseDate(value) {
  if (!value) return null;

  const parsed = new Date(value);

  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}

export function downloadContent(text, fileName, contentType = 'text/plain') {
  const blob = new Blob([text], { type: contentType });

  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.setAttribute('style', 'display: none');

  document.body.appendChild(a);
  a.click();

  // Cleanup
  a.remove();
  URL.revokeObjectURL(url);
};


export async function hasPermission(permission) {
  if (!navigator.permissions) return;

  const permissionStatus = await navigator.permissions.query({ name: permission });
  return permissionStatus.state === 'granted';
}

export function formatDate(date) {
  if (!date) return;

  return new Intl.DateTimeFormat('de', {
    timeStyle: 'medium',
    dateStyle: 'medium',
  }).format(date)
}
