import { LitElement, PropertyValueMap, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles as sharedStyles } from '../styles/shared-styles.js'

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/progress-ring/progress-ring.js';

import { ticketsStore, generalStore, settingsStore } from '../stores.js';
import { withStores } from "@nanostores/lit";
import { map } from 'lit/directives/map.js';
import { when } from 'lit/directives/when.js';
import { Ticket } from '../models/ticket.js';
import { classMap } from 'lit/directives/class-map.js';

import {formatDate} from '../lib/utils.js';


function yn(label, value) {
  return html`
    <sl-icon name=${value ? 'check' : 'x'}></sl-icon> ${label}
  `;
}

@customElement('app-tickets')
export class AppTickets extends withStores(LitElement, [ticketsStore, generalStore, settingsStore]) {
  static styles = [
    sharedStyles,
    css`
      time {
        font-size: var(--sl-font-size-small);
        color: var(--sl-color-neutral-600);
        margin-left: auto;
      }

      .outline {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        border: 1px solid var(--sl-color-neutral-100);
        padding: var(--sl-spacing-small);
        border-radius: 20px;
        margin-bottom: var(--sl-spacing-large);
      }

      .outline {
        text-align: left;
        background-color: transparent
      }

      .outline:hover {
        border-color: var(--sl-color-blue-50);
      }

      .outline:active {
        background-color: var(--sl-color-neutral-50);
      }

      .progress {
        display: flex;
        align-items: center;
        justify-content: start;
      }

      .outline .total {
        margin-left: auto;
        text-align: right;
      }

      .total strong {
        color: var(--sl-color-neutral-800);
        font-size: var(--sl-font-size-large);
      }

      .progress sl-progress-ring {
        margin-right: var(--sl-spacing-x-small);
      }

      .offline sl-progress-ring {
        --indicator-color: var(--sl-color-red-300);
      }

      .offline-note {
        font-size: var(--sl-font-size-small);
        color: var(--sl-color-neutral-600);
      }

      ul.list2 sl-icon {
        margin-right: var(--sl-spacing-small);
      }

      sl-drawer ul.list {
        font-size: var(--sl-font-size-medium);
      }

      sl-drawer ul.list li {
        padding: var(--sl-spacing-small) 0;
      }
    `
  ]

  @state() currentTicket: any;
  @property({type: Object}) page: any;

  get tickets() {
    const tickets = ticketsStore.get()?.values();
    if (!tickets) return [];

    return [...tickets].sort((a, b) => b.scannedAt - a.scannedAt);
  }

  viewTicket(e: Event) {
    const code = (e.currentTarget as HTMLButtonElement).innerText.trim();
    const ticket = ticketsStore.get()?.get(code);

    if (!ticket) return;

    this.currentTicket = ticket;
  }

  renderStatus(ticket: Ticket) {
    const icon = ticket.statusIcon;
    const label = ticket.valid ? 'valid' : 'invalid';
    const color = ticket.valid ? '--sl-color-emerald-500' : '--sl-color-red-500';
    return html`<sl-icon name=${icon} label=${label} style="color:var(${color}); font-size:1.5em;">`;
  }


  renderTicket(ticket: Ticket) {
    return html`
      <li>
        <a class="button" href="/ticket/${ticket.code}">
          ${this.renderStatus(ticket)} ${ticket.code}
          <time>${ticket.scannedAtHuman}</time>
        </a>
      </li>
    `;
  }

  renderList() {
    return html`
      <ul class="list2">
        ${map(this.tickets, ticket => this.renderTicket(ticket))}
      </ul>
    `;
  }

  renderEmpty() {
    return html`
      <div class="empty">
        <sl-icon name="ticket" label="ticket"></sl-icon>
        <h2>No tickets scanned yet</h2>
        <p>Scan a ticket to see it here</p>
      </div>
    `;
  }

  clearCurrentTicket() {
    history.back();
  }

  get drawer() {
    return this.shadowRoot?.querySelector('sl-drawer');
  }

  protected willUpdate(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.willUpdate(changedProperties);

    if (changedProperties.has('page')) {
      this.currentTicket = ticketsStore.get()?.get(this.page.params.code);
    }

    if (changedProperties.has('currentTicket') && this.currentTicket) {
      requestAnimationFrame(() => this.drawer?.show() );
    }
  }

  renderDrawer() {
    const ticket = this.currentTicket;

    if(!ticket) return;

    return html`
      <sl-drawer
        @sl-after-hide=${this.clearCurrentTicket}
        placement="bottom"
        label="Ticket details ${ticket.code}">

        <ul class="list">
          <li>${yn('Scanned', ticket.scanned)} <time>${formatDate(ticket.scannedAt)}</time></li>
          <li>${yn('Valid', ticket.valid)}</li>
          <li>${yn('Synced', ticket.synced)} <time>${formatDate(ticket.syncedAt)}</time></li>
          <li>${yn('Used', ticket.used)} <time>${formatDate(ticket.usedAt)}</time></li>
          <!-- <li>Status status was  <small>${ticket.status}</small></li> -->
        </ul>

      </sl-drawer>
    `
  }

  get offlineMode() {
    return settingsStore.get()?.offlineMode;
  }

  renderProgress() {
    return html`
      <div class="progress">
        <sl-progress-ring value=${Ticket.syncPercentage} style="--size: 32px;">
        ${when(Ticket.syncing,
          () => html`<sl-spinner style="font-size:16px;"></sl-spinner>`
        )}
        </sl-progress-ring>



        <div>
          ${Ticket.syncPercentage}% synced<br>
          <time>${formatDate(Ticket.lastSyncedAt)}</time>
        </div>
      </div>
    `;
  }

  renderOfflineNote() {
    return html`
      <div class="offline-note">
        Tap to go online
      </div>
    `;
  }

  onStatusClick() {
    if (this.offlineMode) {
      settingsStore.setKey('offlineMode', false);
    }

    Ticket.periodicSyncAll();
  }

  render() {
    return html`
      <app-header logo></app-header>

      <main>
        <button class=${classMap({
          outline: true,
          offline: this.offlineMode
        })} @click=${this.onStatusClick}  >
            ${this.renderProgress()}

            <div class="total">
              <strong>${Ticket.counters.total} tickets</strong>
              ${when(this.offlineMode, () => this.renderOfflineNote())}
            </div>
        </button>


        ${when(this.tickets.length, () => this.renderList(), () => this.renderEmpty())}

      </main>

      ${this.renderDrawer()}

      <app-footer></app-footer>
    `;
  }
}
