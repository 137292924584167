import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'Ticket check';

  @property({ type: Boolean }) enableBack: boolean = false;
  @property({ type: Boolean }) logo: boolean = false;

  static styles = css`
    header {
      z-index: 100;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--app-background-color);
      // background: var(--sl-color-neutral-500); // for testing
      color: var(--sl-color-neutral-1000);
      height: 4em;
      padding-inline: var(--sl-spacing-medium);

      position: fixed;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);
      height: env(titlebar-area-height, 60px);
      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;
    }

    header h1 {
      margin-top: 2px;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: normal;
    }

    .logo {
      display: flex;
      align-items: center;
      font-size: 1.3em;
      margin-right: 20px;
      text-decoration: none;
      color: var(--sl-color-neutral-1000);
    }

    .logo img {
      width: 30px;
      margin-right: 10px;
    }

    .logo h1 {
      white-space: nowrap;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .right > * {
      margin-left: var(--sl-spacing-small);
    }

    nav a {
      margin-left: 10px;
    }

    #back {
      margin-right: var(--sl-spacing-x-small);
    }

    #back::part(base) {
      border:none;
      font-size: 1.2em;
    }

    #back::part(base):not(:active):not(:focus):not(:hover) {
      background-color: transparent;
    }

    sl-icon {
      vertical-align: middle;
      font-size: 1.5em;
    }
  `;

  renderBackButton() {
    return html`
      <sl-button id="back" circle href="${resolveRouterPath()}">
        <sl-icon name="chevron-left" label="Back"></sl-icon>
      </sl-button>
    `;
  }

  render() {
    return html`
      <header>
        <div class="left">
          ${this.enableBack ? this.renderBackButton() : null}

          <a class="logo" href=${resolveRouterPath()}>
            ${this.logo
              ? html`<img src="/assets/ticket_check_logo.svg" alt="Ticket check logo" />`
              : null}
            <h1>${this.title}</h1>
          </a>
        </div>

        <div class="right">
          <slot name="right"></slot>
          <app-menu></app-menu>
        </div>
      </header>
    `;
  }
}

