import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { downloadContent } from '../lib/utils.js';

import  { VERSION } from '../version.js';

import { styles as sharedStyles } from '../styles/shared-styles'

import '@shoelace-style/shoelace/dist/components/card/card.js';

import { withStores } from "@nanostores/lit";
import {configStore, settingsStore, ticketsStore} from '../stores.js';

window.ticketsStore = ticketsStore;

@customElement('app-settings')
export class AppSettings extends  withStores(LitElement, [configStore, settingsStore]) {
  static styles = [
    sharedStyles,
    css`

    `
  ]

  @property({ type: Object }) appConfig: any = {
    version: VERSION
  };


  get config() {
    return configStore.get();
  }

  get settings() {
    return settingsStore.get();
  }

  set settings(value) {
    settingsStore.set(value);
  }

  updateSettings(event) {
    const key = event.target.dataset.key;
    const value = event.target.checked;

    settingsStore.set({
      ...this.settings,
      [key]: value
    });
  }

  exportData() {
    const data = [
      ...ticketsStore.get().values(),
    ];

    const dataString = JSON.stringify(data, null, 2);
    downloadContent(dataString, `ticket_check_export_${Date.now()}.json`, 'application/json');

    // const file = new File(
    //   [dataString],
    //   `ticket_check_export_${Date.now()}.json.txt`,
    //   { type: 'text/plain' }
    // );

    // navigator.share({
    //   title: 'Exported ticket check data',
    //   text: 'Ticket check data',
    //   files: [file],
    // });

  }

  renderSetting({key, label, description}) {
    const checked = this.settings[key];

    return html`
      <li>
        <sl-switch data-key=${key} ?checked=${checked} @sl-input=${this.updateSettings}>
          ${label}
          ${description && html`<span class="desc">${description}</span>`}
        </sl-switch>
      </li>
    `;
  }

  render() {
    return html`
      <app-header enableBack title="Settings"></app-header>
      <main>

        <h2 class="section-title">All</h2>
        <section>
          <ul class="list settings">
            ${this.renderSetting({
              key: 'offlineMode',
              label: 'Offline mode',
              description: 'When enabled, the app will not verify tickets on the server, only locally'
              })}
          </ul>
        </section>


        <h2 class="section-title">Dialogs</h2>
        <section>
          <ul class="list settings">
            ${this.renderSetting({
              key: 'samsungBrowserDialogDismissed',
              label: 'Show Samsung Browser color dialog',
              description: 'Samsung internet browser overrides the colors of the application. This dialog will show up if you are using this browser and present solution.'
              })}
          </ul>
        </section>

        <h2 class="section-title">Export</h2>
        <section class="card">
          <p>This will download all the scanned tickets as JSON</p>
          <sl-button variant="primary" @click=${this.exportData}>Export tickets</sl-button>
        </section>

      </main>
    `;
  }
}
