import { persistentAtom, persistentMap } from '@nanostores/persistent'
import { Ticket } from './models/ticket.js'

function reviver(key, value) {
  if (typeof value === 'string' && key.endsWith('At')) {
    return new Date(value);
  }

  return value;
}

function setKey(key, value) {
  this.set({
    ...this.get(),
    [key]: value
  })
}

const transformer = {
  encode: JSON.stringify,
  decode: (str) => JSON.parse(str, reviver),
}

export const settingsStore = persistentAtom('settingsStore', {}, transformer)

export const configStore = persistentAtom('config', {}, transformer)

export const generalStore = persistentAtom('general', {}, transformer)

generalStore.setKey = setKey;
configStore.setKey = setKey;
settingsStore.setKey = setKey;

generalStore.getKey = function (key) {
  return this.get()[key]
}

export const ticketsStore = persistentAtom('ticketsStore', [], {
  encode: (map) => {
    return JSON.stringify([...map.values()])
  },
  decode: (str) => {
    const arr = JSON.parse(str) || [];
    return new Map(arr.map(item => [item.code, new Ticket(item)]))
  },
})
