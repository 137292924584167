import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { requestPersistedStorage } from '../lib/utils.js';

import  { VERSION } from '../version.js';

import { styles as sharedStyles } from '../styles/shared-styles'

import '@shoelace-style/shoelace/dist/components/card/card.js';
import { map } from 'lit/directives/map.js';

import { withStores } from "@nanostores/lit";
import {configStore} from '../stores.js';

@customElement('app-info')
export class AppInfo extends  withStores(LitElement, [configStore]) {
  static styles = [
    sharedStyles,
    css`
      .permission {
        font-size: var(--sl-font-size-large);
        display: flex;
        align-items: center;
        margin-bottom: var(--sl-spacing-large);
        border-bottom: 1px solid var(--sl-color-gray-300);
        padding-bottom: var(--sl-spacing-large);

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      .permission sl-icon {
        margin-right: 10px;
      }

    `
  ]

  @property({ type: Object }) permissions: any = new Map();
  @property({ type: Object }) appConfig: any = {
    version: VERSION
  };


  get config() {
    return configStore.get();
  }


  async getPermission(name: PermissionName) {
    let granted = false;

    // iOS fallback for checking persistent storage
    if(name === 'persistent-storage') {
      granted = await navigator.storage.persisted()
    } else {
      const { state } = await navigator.permissions.query({ name });
      granted = state === 'granted'
    }

    this.permissions.set(name, granted);
    this.requestUpdate();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.getPermission('camera' as PermissionName);
    this.getPermission('persistent-storage' as PermissionName);
  }

  renderPermission(item) {
    const [name, granted] = item;
    console.log(name, granted)
    return html`
      <article class="permission">
        <sl-icon name="${granted ? 'check-circle' : 'x-circle'}"></sl-icon>
        <span>${name}</span>
      </article>
    `;
  }

  renderConfig(data = this.config) {
    return html`
      ${map(Object.entries(data), ([key, value]) => html`
        <dl>
          <dt>${key}</dt>
          <dd>${value}</dd>
        </dl>
      `)}
    `;
  }

  render() {
    return html`
      <app-header enableBack title="About"></app-header>
      <main>

        <section class="hero">
        <sl-avatar
          style="--size: 64px;"
          image="/assets/icons/192x192.png"
          label="Ticket check logo"
        ></sl-avatar>
          <h2>Ticket check</h2>
          <p>Made with ❤️ by Effectiva studio Ltd.</p>
        </section>


        <h2 class="section-title">Data</h2>
        <section class="card">
          ${this.renderConfig()}
        </section>

        <h2 class="section-title">Permissions</h2>
        <section class="card">
          ${map(this.permissions, (key) => this.renderPermission(key))}
        </section>

        <h2 class="section-title">App</h2>
        <section class="card">
          ${this.renderConfig(this.appConfig)}
        </section>
      </main>
    `;
  }
}
