// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { setDebug } from '@thepassle/app-tools/utils/log.js';
// setDebug(true);

import { Router } from '@thepassle/app-tools/router.js';
import { checkServiceWorkerUpdate } from '@thepassle/app-tools/router/plugins/checkServiceWorkerUpdate.js';

import './pages/app-home.js';
import './pages/app-info.js';
import './pages/app-tickets.js';
import './pages/app-settings.js';

const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
    /** Plugins to be run for every route */
    plugins: [
      /** Checks for service worker updates on route navigations */
      checkServiceWorkerUpdate,
    ],
    routes: [
      {
        path: resolveRouterPath(),
        title: 'Home',
        render: () => html`<app-home><slot></slot></app-home>`
      },
      {
        path: resolveRouterPath('info'),
        title: 'Info',
        render: () => html`<app-info></app-info>`
      },
      {
        path: resolveRouterPath('settings'),
        title: 'Settings',
        render: () => html`<app-settings></app-settings>`
      },
      {
        path: resolveRouterPath('about'),
        title: 'Tickets',
        // plugins: [
        //   lazy(() => import('./pages/app-tickets/app-tickets.js')),
        // ],
        render: (page) => html`<app-tickets> .page=${page}</app-tickets>`
      },
      {
        path: resolveRouterPath('ticket/:code'),
        title: ({params}) => `Tickets/${params.code}`,
        render: (page) => html`<app-tickets .page=${page}></app-tickets>`,
      }
    ]
  });

  // window.router = router;

  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.

  export function resolveRouterPath(unresolvedPath?: string) {
    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }

    return resolvedPath;
  }
